import React, {useState, ReactNode} from 'react';
import { Link, useLocation } from 'react-router-dom';
import SearchInput from '../form/search-input';
import SvgLogo from '../svg-logo';
import { Auth, useAuthStore } from '../../lib/auth/auth-store';

interface LayoutProps {
    children: ReactNode;
  }

const AuthLayout : React.FC <LayoutProps>= ({children}) => {
    const {handleLogout} = useAuthStore();
    const {name} = Auth();

    const [showNav, setShowNav] = useState<boolean>(false);

    const currentUrl = useLocation().pathname;

    const onSidebar =  () => {
        const sidebar = document.getElementById('sidebar');
        setShowNav(!showNav)
        setTimeout(() =>{
            sidebar?.classList.toggle('-translate-x-full');
            sidebar?.classList.toggle('translate-x-0'); 
        }, 1)  
    }

    return (
        <div className='flex-col min-h-screen'>
            <div className="relative flex w-full m-0 m-h-screen">
            {/* sidebar */}
            <div id='sidebar' className={`${showNav ? 'absolute w-3/5 z-50 h-full shadow' : 'hidden w-1/3 min-h-screen '} flex flex-col transform -translate-x-full md:translate-x-0 transition-transform duration-500 ease-in-out px-10 py-5 pb-20 lg:w-1/5 bg-primary-light md:block`}>
                <div className='relative flex items-center mt-5 space-x-3'>
                    <SvgLogo/>
                    <h1 className='text-primary'>cdash</h1>
                    <p  className='absolute right-0 flex items-center justify-center w-10 h-10 text-[10px] text-white rounded-full -top-2 md:hidden bg-tertiary' 
                        onClick={()=> onSidebar()}>
                        x
                    </p>
                </div>
                <menu className='mt-[5em] font-[400] text-[14px]'>
                    <div className='grid gap-y-10'>
                        <Link to='/' className={`${currentUrl === '/' && 'font-[700] text-primary'} flex space-x-3  hover:text-primary hover:font-[700]`}>
                            <div>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M13.507 2.138C13.3277 2.03289 13.1202 1.98623 12.9132 2.00451C12.7062 2.02279 12.51 2.1051 12.352 2.24L4.19599 9.197C1.27199 12.121 1.27199 16.879 4.19599 19.803C4.8915 20.4994 5.71776 21.0516 6.6273 21.4278C7.53684 21.8039 8.51173 21.9967 9.49599 21.995C11.42 21.995 13.346 21.261 14.813 19.793L21.716 12.697C21.8529 12.5561 21.9453 12.378 21.9817 12.1849C22.018 11.9918 21.9966 11.7923 21.9203 11.6113C21.8439 11.4302 21.7159 11.2757 21.5522 11.167C21.3885 11.0583 21.1965 11.0002 21 11H17.699L21.874 3.486C21.9785 3.29608 22.0186 3.07734 21.9882 2.86269C21.9577 2.64803 21.8584 2.44907 21.7052 2.29571C21.5519 2.14235 21.353 2.04289 21.1384 2.01228C20.9238 1.98168 20.705 2.02159 20.515 2.126L13.405 6.076L13.981 3.197C14.0216 2.99312 13.9978 2.78166 13.9129 2.59192C13.8279 2.40217 13.6861 2.24354 13.507 2.138ZM14 14.5C14 15.6935 13.5259 16.8381 12.682 17.682C11.8381 18.5259 10.6935 19 9.49999 19C8.30652 19 7.16192 18.5259 6.31801 17.682C5.4741 16.8381 4.99999 15.6935 4.99999 14.5C4.99999 12.93 5.80699 11.551 7.02499 10.746C7.01499 10.83 6.99999 10.913 6.99999 11C6.99999 11.296 7.06571 11.5884 7.1924 11.8559C7.31909 12.1235 7.50359 12.3596 7.73259 12.5472C7.9616 12.7347 8.22939 12.8692 8.51665 12.9407C8.8039 13.0123 9.10346 13.0191 9.39369 12.9609C9.68393 12.9026 9.95761 12.7806 10.195 12.6037C10.4324 12.4269 10.6275 12.1995 10.7664 11.938C10.9052 11.6766 10.9843 11.3876 10.9979 11.0919C11.0115 10.7961 10.9593 10.5011 10.845 10.228C12.669 10.802 14 12.486 14 14.5Z" 
                            fill={`${currentUrl === '/' ? '#135EE5' : 'black'}`}/>
                            </svg>
                            </div>
                            <h3>Metrics</h3>
                        </Link>
                        <Link to='/transactions' className={`${currentUrl.includes('/transactions') && 'font-[700] text-primary'} flex space-x-3  hover:text-primary hover:font-[700]`}>
                            <div>
                            <svg width="27" height="24" viewBox="0 0 27 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M18.5 4C16.57 4 15 5.57 15 7.5C15 9.054 16.025 10.359 17.43 10.815C17.284 11.747 16.883 12.515 16.2 13.138C14.254 14.911 10.673 15.073 9 15.045V8.837C10.44 8.403 11.5 7.08 11.5 5.5C11.5 3.57 9.93 2 8 2C6.07 2 4.5 3.57 4.5 5.5C4.5 7.08 5.56 8.403 7 8.837V15.163C5.56 15.597 4.5 16.92 4.5 18.5C4.5 20.43 6.07 22 8 22C9.93 22 11.5 20.43 11.5 18.5C11.5 17.949 11.36 17.435 11.133 16.971C13.193 16.785 15.79 16.214 17.542 14.621C18.639 13.624 19.273 12.357 19.446 10.853C20.915 10.438 22 9.1 22 7.5C22 5.57 20.43 4 18.5 4ZM6.5 5.5C6.5 4.673 7.173 4 8 4C8.827 4 9.5 4.673 9.5 5.5C9.5 6.327 8.827 7 8 7C7.173 7 6.5 6.327 6.5 5.5ZM8 20C7.173 20 6.5 19.327 6.5 18.5C6.5005 18.1056 6.65629 17.7273 6.93366 17.4469C7.21103 17.1666 7.58765 17.0067 7.982 17.002L8.112 17.012C8.30819 17.0194 8.50102 17.0653 8.67947 17.1472C8.85791 17.229 9.01849 17.3452 9.15202 17.4892C9.28555 17.6331 9.38942 17.8019 9.45771 17.986C9.52599 18.1701 9.55735 18.3658 9.55 18.562C9.54265 18.7582 9.49672 18.951 9.41485 19.1295C9.33297 19.3079 9.21675 19.4685 9.07282 19.602C8.92889 19.7355 8.76007 19.8394 8.57599 19.9077C8.39192 19.976 8.19619 20.0074 8 20ZM18.5 9C17.673 9 17 8.327 17 7.5C17 6.673 17.673 6 18.5 6C19.327 6 20 6.673 20 7.5C20 8.327 19.327 9 18.5 9Z" 
                            fill={`${currentUrl.includes('/transactions') ? '#135EE5' : 'black'}`}/>
                            </svg>
                            </div>
                            <h3>Transactions</h3>
                        </Link>
                        <Link to='/' className={`${currentUrl === '/savings' && 'font-[700] text-primary'} flex space-x-3  hover:text-primary hover:font-[700]`}>
                            <div>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12 2C6.486 2 2 6.486 2 12C2 17.514 6.486 22 12 22C17.514 22 22 17.514 22 12C22 6.486 17.514 2 12 2ZM12 20C7.589 20 4 16.411 4 12C4 7.589 7.589 4 12 4C16.411 4 20 7.589 20 12C20 16.411 16.411 20 12 20Z" fill="black"/>
                            <path d="M12 5.16604V12H18.834C18.8362 11.102 18.661 10.2123 18.3184 9.38213C17.9757 8.55198 17.4724 7.79771 16.8374 7.16267C16.2023 6.52763 15.4481 6.02432 14.6179 5.68168C13.7878 5.33903 12.8981 5.16379 12 5.16604Z" fill="black"/>
                            </svg>
                            </div>
                            <h3>Savings</h3>
                        </Link>
                        <Link to='/' className={`${currentUrl === '/loans' && 'font-[700] text-primary'} flex space-x-3  hover:text-primary hover:font-[700]`}>
                            <div>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12 20C13.1046 20 14 19.1046 14 18C14 16.8954 13.1046 16 12 16C10.8954 16 10 16.8954 10 18C10 19.1046 10.8954 20 12 20Z" fill="black"/>
                            <path d="M18 14C19.1046 14 20 13.1046 20 12C20 10.8954 19.1046 10 18 10C16.8954 10 16 10.8954 16 12C16 13.1046 16.8954 14 18 14Z" fill="black"/>
                            <path d="M12 14C13.1046 14 14 13.1046 14 12C14 10.8954 13.1046 10 12 10C10.8954 10 10 10.8954 10 12C10 13.1046 10.8954 14 12 14Z" fill="black"/>
                            <path d="M6 14C7.10457 14 8 13.1046 8 12C8 10.8954 7.10457 10 6 10C4.89543 10 4 10.8954 4 12C4 13.1046 4.89543 14 6 14Z" fill="black"/>
                            <path d="M18 8C19.1046 8 20 7.10457 20 6C20 4.89543 19.1046 4 18 4C16.8954 4 16 4.89543 16 6C16 7.10457 16.8954 8 18 8Z" fill="black"/>
                            <path d="M12 8C13.1046 8 14 7.10457 14 6C14 4.89543 13.1046 4 12 4C10.8954 4 10 4.89543 10 6C10 7.10457 10.8954 8 12 8Z" fill="black"/>
                            <path d="M6 8C7.10457 8 8 7.10457 8 6C8 4.89543 7.10457 4 6 4C4.89543 4 4 4.89543 4 6C4 7.10457 4.89543 8 6 8Z" fill="black"/>
                            </svg>
                            </div>
                            <h3>Loans</h3>
                        </Link>
                        <Link to='/' className={`${currentUrl === '/agents' && 'font-[700] text-primary'} flex space-x-3  hover:text-primary hover:font-[700]`}>
                            <div>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M14.683 14.828C14.3175 15.1923 13.8857 15.4835 13.411 15.686C12.5563 16.0468 11.6028 16.0995 10.7135 15.835C9.82428 15.5705 9.05455 15.0053 8.53599 14.236L6.87799 15.355C7.31043 15.9931 7.86059 16.5429 8.49899 16.975C9.14864 17.4143 9.87858 17.7211 10.647 17.878C11.836 18.12 13.0704 17.9981 14.189 17.528C14.9009 17.2258 15.5483 16.79 16.096 16.244C16.368 15.973 16.616 15.673 16.83 15.355L15.172 14.236C15.0276 14.448 14.8639 14.6462 14.683 14.828Z" fill="black"/>
                            <path d="M12 2C6.486 2 2 6.486 2 12C2 17.514 6.486 22 12 22C17.514 22 22 17.514 22 12C22 6.486 17.514 2 12 2ZM12 4C14.953 4 17.531 5.613 18.918 8H5.082C6.469 5.613 9.047 4 12 4ZM12 20C7.589 20 4 16.411 4 12C4 11.309 4.098 10.641 4.264 10H5V11C5 11.5304 5.21071 12.0391 5.58579 12.4142C5.96086 12.7893 6.46957 13 7 13H9C9.53043 13 10.0391 12.7893 10.4142 12.4142C10.7893 12.0391 11 11.5304 11 11H13C13 11.5304 13.2107 12.0391 13.5858 12.4142C13.9609 12.7893 14.4696 13 15 13H17C17.5304 13 18.0391 12.7893 18.4142 12.4142C18.7893 12.0391 19 11.5304 19 11V10H19.736C19.902 10.641 20 11.309 20 12C20 16.411 16.411 20 12 20Z" fill="black"/>
                            </svg>
                            </div>
                            <h3>Agent</h3>
                        </Link>
                        <Link to='/user-accounts' className={`${currentUrl.includes('/user-accounts') && 'font-[700] text-primary'} flex space-x-3  hover:text-primary hover:font-[700]`}>
                            <div>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clipPath="url(#clip0_2877_47004)">
                            <path d="M16.018 3.81494L15.232 7.99994H10.266L10.982 4.18494L9.018 3.81494L8.232 7.99994H4V9.99994H7.857L7.106 13.9999H3V15.9999H6.731L6.017 19.8049L7.982 20.1739L8.766 15.9999H13.732L13.018 19.8049L14.983 20.1739L15.766 15.9999H20V13.9999H16.141L16.892 9.99994H21V7.99994H17.267L17.983 4.18494L16.018 3.81494ZM14.106 13.9999H9.141L9.892 9.99994H14.858L14.106 13.9999Z" 
                            fill={`${currentUrl.includes('/user-accounts') ? '#135EE5' : 'black'}`}/>
                            </g>
                            <defs>
                            <clipPath id="clip0_2877_47004">
                            <rect width="24" height="24" fill="white"/>
                            </clipPath>
                            </defs>
                            </svg>
                            </div>
                            <h3>User Accounts</h3>
                        </Link>
                    </div>
                    <div className='grid gap-y-10 mt-[5em]'>
                        <Link to='/settings' className={`${currentUrl === '/settings' && 'font-[700] text-primary'} flex space-x-3  hover:text-primary hover:font-[700]`}>
                            <div>
                            <svg width="27" height="24" viewBox="0 0 27 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M13.2857 14.5701C14.8318 14.5701 16.0852 13.4194 16.0852 12.0001C16.0852 10.5807 14.8318 9.43005 13.2857 9.43005C11.7396 9.43005 10.4863 10.5807 10.4863 12.0001C10.4863 13.4194 11.7396 14.5701 13.2857 14.5701Z" 
                            stroke={`${currentUrl === '/settings' ? '#135EE5' : '#000'}`} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            <path fillRule="evenodd" clipRule="evenodd" d="M22.5664 8.27L21.4444 6.49C20.976 5.75 19.9521 5.5 19.1461 5.93L18.5905 6.22C17.4686 6.81 16.0743 6.07 16.0743 4.88V4.29C16.0743 3.44 15.3227 2.75 14.3968 2.75H12.1528C11.2269 2.75 10.4754 3.44 10.4754 4.29V4.88C10.4754 6.07 9.08104 6.81 7.95908 6.22L7.40356 5.93C6.59749 5.5 5.57354 5.76 5.10515 6.49L3.98319 8.27C3.5148 9.01 3.79801 9.95 4.59319 10.38L5.14873 10.67C6.2707 11.26 6.2707 12.75 5.14873 13.34L4.59319 13.63C3.78711 14.06 3.5148 15 3.98319 15.74L5.10515 17.52C5.57354 18.26 6.59749 18.51 7.40356 18.08L7.95908 17.79C9.08104 17.2 10.4754 17.94 10.4754 19.13V19.72C10.4754 20.57 11.2269 21.26 12.1528 21.26H14.3968C15.3227 21.26 16.0743 20.57 16.0743 19.72V19.13C16.0743 17.94 17.4686 17.2 18.5905 17.79L19.1461 18.08C19.9521 18.51 20.976 18.25 21.4444 17.52L22.5664 15.74C23.0348 15 22.7516 14.06 21.9564 13.63L21.4009 13.34C20.2789 12.75 20.2789 11.26 21.4009 10.67L21.9564 10.38C22.7625 9.95 23.0348 9 22.5664 8.27Z" 
                                stroke={`${currentUrl === '/settings' ? '#135EE5' : '#000'}`} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                            </div>
                            <h3>Settings</h3>
                        </Link>
                        <Link to='/' className={`${currentUrl === '/help' && 'font-[700] text-primary'} flex space-x-3  hover:text-primary hover:font-[700]`}>
                            <div>
                            <svg width="27" height="24" viewBox="0 0 27 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M11.837 19.97C11.5756 19.99 11.3141 20 11.0527 20C9.77823 20 8.56912 19.72 7.49072 19.23L3.2534 19.79L3.89609 15.88H3.90698C3.47127 15 3.20984 14.03 3.20984 13C3.20984 11.11 4.04858 9.40001 5.3993 8.14001" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M12.0439 11.78C12.5252 11.78 12.9153 11.4308 12.9153 11C12.9153 10.5692 12.5252 10.22 12.0439 10.22C11.5626 10.22 11.1725 10.5692 11.1725 11C11.1725 11.4308 11.5626 11.78 12.0439 11.78Z" fill="black"/>
                            <path d="M15.5296 11.78C16.0109 11.78 16.4011 11.4308 16.4011 11C16.4011 10.5692 16.0109 10.22 15.5296 10.22C15.0484 10.22 14.6582 10.5692 14.6582 11C14.6582 11.4308 15.0484 11.78 15.5296 11.78Z" fill="black"/>
                            <path d="M19.0044 11.78C19.4857 11.78 19.8759 11.4308 19.8759 11C19.8759 10.5692 19.4857 10.22 19.0044 10.22C18.5232 10.22 18.133 10.5692 18.133 11C18.133 11.4308 18.5232 11.78 19.0044 11.78Z" fill="black"/>
                            <path d="M22.6753 13.88L23.318 17.79L19.0807 17.23C18.3182 17.58 17.4903 17.82 16.608 17.93C16.2594 17.98 15.8891 18 15.5187 18C11.1834 18 7.68677 14.87 7.68677 11C7.68677 9.07 8.5473 7.35 9.96337 6.08C11.3685 4.79 13.3402 4 15.5296 4C19.865 4 23.3616 7.13 23.3616 11C23.3616 12.03 23.1002 13 22.6644 13.88H22.6753V13.88Z" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                            </div>
                            <h3>Help</h3>
                        </Link>
                        <Link to='/' onClick={handleLogout} className={`flex space-x-3  hover:text-primary hover:font-[700]`}>
                            <div>
                            <svg width="27" height="24" viewBox="0 0 27 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M17.9152 17.14V18.4C17.9152 19.98 16.3902 21.25 14.5057 21.25H6.60837C4.7239 21.25 3.19891 19.97 3.19891 18.4V5.6C3.19891 4.02 4.7239 2.75 6.60837 2.75H14.5057C16.3902 2.75 17.9152 4.03 17.9152 5.6V6.86" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M12.5014 12H23.3398" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M23.3616 12L19.7888 8.73999" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M23.3616 12L19.7888 15.26" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                            </div>
                            <h3>Logout</h3>
                        </Link>
                    </div>
                </menu>
            </div>
            <div className='w-full px-5 md:w-4/5 lg:px-[3em] py-7'>
                {/* header */}
                <div className='flex items-center justify-between pb-6 border-b border-[#F0F0F0]'>
                    <div className='flex items-center space-x-3 md:hidden'>
                        <span onClick={()=> onSidebar()}>
                            <svg width="16" height="10" viewBox="0 0 16 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M14 10C15.1046 10 16 9.10457 16 8C16 6.89543 15.1046 6 14 6C12.8954 6 12 6.89543 12 8C12 9.10457 12.8954 10 14 10Z" fill="black"/>
                            <path d="M8 10C9.10457 10 10 9.10457 10 8C10 6.89543 9.10457 6 8 6C6.89543 6 6 6.89543 6 8C6 9.10457 6.89543 10 8 10Z" fill="black"/>
                            <path d="M2 10C3.10457 10 4 9.10457 4 8C4 6.89543 3.10457 6 2 6C0.89543 6 0 6.89543 0 8C0 9.10457 0.89543 10 2 10Z" fill="black"/>
                            <path d="M14 4C15.1046 4 16 3.10457 16 2C16 0.89543 15.1046 0 14 0C12.8954 0 12 0.89543 12 2C12 3.10457 12.8954 4 14 4Z" fill="black"/>
                            <path d="M8 4C9.10457 4 10 3.10457 10 2C10 0.89543 9.10457 0 8 0C6.89543 0 6 0.89543 6 2C6 3.10457 6.89543 4 8 4Z" fill="black"/>
                            <path d="M2 4C3.10457 4 4 3.10457 4 2C4 0.89543 3.10457 0 2 0C0.89543 0 0 0.89543 0 2C0 3.10457 0.89543 4 2 4Z" fill="black"/>
                            </svg>
                        </span>
                            <svg width="38" height="34" viewBox="0 0 38 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M24.6142 33.2453H13.4923C6.60359 33.2453 0.946411 28.0518 0.946411 21.7278V11.5175C0.946411 5.19348 6.60359 0 13.4923 0H24.6142C31.5029 0 37.1601 5.19348 37.1601 11.5175V21.7631C37.1601 28.0872 31.5029 33.2453 24.6142 33.2453Z" fill="#135EE5"/>
                            <path d="M10.0674 14.5911V22.3637L24.1911 24.6954C24.1911 22.081 24.1911 19.4666 24.1911 16.8876L10.0674 14.5911Z" fill="#ECE71C"/>
                            <path d="M14.6085 9.57422V17.3468L28.7322 19.6785C28.7322 17.0641 28.7322 14.4497 28.7322 11.8707L14.6085 9.57422Z" fill="#ECE71C"/>
                            </svg>
                            <h1 className='text-primary'>cdash</h1>  
                        </div>
                    {/* search */}
                    <div className='hidden md:block'><SearchInput placeholder='Search'/></div>
                    {/* --/ search */}
                    <div className='flex items-center space-x-10'>
                        <div className='relative'>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M20.22 16.62C20.22 17.47 19.53 18.16 18.68 18.16H5.32004C4.47004 18.16 3.78003 17.47 3.78003 16.62C3.78003 15.77 4.47004 15.08 5.32004 15.08H5.83002V9.94C5.83002 6.54 8.59003 3.77 12 3.77C13.7 3.77 15.24 4.46 16.36 5.58C17.48 6.69 18.17 8.23 18.17 9.94V15.08H18.68C19.53 15.08 20.22 15.77 20.22 16.62Z" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M12 3.78V2.75" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M15.08 18.17C15.08 19.88 13.7 21.25 12 21.25C10.3 21.25 8.92004 19.87 8.92004 18.17H15.08Z" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                            <div className='w-1 h-1 p-1 bg-[#CF4306] rounded-full absolute top-[1.5px] right-[1.5px]'></div>
                        </div>
                        <div className='flex items-center space-x-2'>
                            <div className='flex items-center justify-center w-10 h-10 overflow-hidden text-xs text-green-800 bg-gray-300 rounded-full'>
                                MH
                            </div>
                            <div className='flex items-center space-x-2'>
                                <h3 className='font-[700] text-tertiary text-[12px] capitalize'>{name}</h3>
                                <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M5.00008 6L0.75708 1.757L2.17208 0.343002L5.00008 3.172L7.82808 0.343002L9.24308 1.757L5.00008 6Z" fill="#1F4173"/>
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='my-8'>
                {children}
                </div>
            </div>
        </div>
        </div>
    );
}

export default AuthLayout;