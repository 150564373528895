import React,{useState, useEffect, useRef} from "react";
import AuthLayout from "../../../components/layouts/auth";
import SearchInput from "../../../components/form/search-input";
import { CalendarDays, ChevronDown, Filter, Minus } from "lucide-react";
import { Link } from "react-router-dom";
import TablePagination from "../../../components/table/table-pagination";
import ApiSupport from "../../../lib/api-support";
import moment from "moment";
import TableSkeleton from "../../../components/loader/table-placeholder-loader";
import { numberWithCommas } from "../../../lib/helper-support";
import { ErrorFlash, SuccessFlash } from "../../../components/helper/flash-notification";
import SpinnerLoader from "../../../components/loader/spinner-loader";

const UserAccounts : React.FC = () => {
    const [loadingUsers, setLoadingUsers] = useState<boolean>(true);

    const [userData, setUserData] = useState<UserDataProp | undefined>(undefined);

    const [userAccounts, setUserAccounts] = useState<userAccountsType []>([]);

    const [currentPage, setCurrentPage] = useState<number>(1);

    const [userActivityState, setUserActivityState] = useState<string>('all');

    const userActivityStates : string [] = ['all', 'active', 'dormant', 'deactivated'];

    const [showFilters, setShowFilters] = useState<boolean>(false);

    const showFiltersRef = useRef<HTMLDivElement>(null);

    const [selectedUserAccounts, setSelectedUserAccounts] = useState<string[]>([]);

    const [loadingExport, setLoadingExport] = useState<boolean>(false);

    //filtering
    const [filterVirtualAccount, setFilterVirtualAccount] = useState<string>('all');
    const [filterVerifyEmail, setFilterVerifyEmail] = useState<string>('all');
    const [filterVerifyPhoneNumber, setFilterVerifyPhoneNumber] = useState<string>('all');
    const [filterAccountActive, setFilterAccountActive] = useState<string>('all');
    const [filterGender, setFilterGender] = useState<string>('all');
    const [filterAccountActivated, setFilterAccountActivated] = useState<string>('all');
    const [createdFrom, setCreatedFrom] = useState<string>(moment().startOf('M').format('YYYY-MM-DD'));
    const [createdTo, setCreatedTo] = useState<string>(moment().endOf('M').format('YYYY-MM-DD'));
 
    type userAccountsType = {
        userId: string,
        name: string,
        cTag: string,
        email: string,
        virtualAccount: string,
        activeSince: string,
        status: string,
        statusColor: string,
    }

    type UserDataProp = {
        items:userAccountsType [],
        page: number,
        nextPage: number,
        hasMorePages: boolean,
        totalPages: number,
        total?: number,
    };

    const ExportUsers = async () => {
        setLoadingExport(true);
        
        let url = `users/export`;
        url += `?account_status=${userActivityState}`;
        url += `&has_virtual_account=${filterVirtualAccount}`;
        url += `&verified_email=${filterVerifyEmail}`;
        url += `&verified_phone_number=${filterVerifyPhoneNumber}`;
        url += `&account_active=${filterAccountActive}`;
        url += `&account_activated=${filterAccountActivated}`;
        url += `&gender=${filterGender}`;
        url += `&created_from=${createdFrom}`;
        url += `&created_until=${createdTo}`;

        const request = await (new ApiSupport()).post(url, []);

        setLoadingExport(false);
        if ( request === 'error' ){
            ErrorFlash('Something went wrong');
            return;
        }
        SuccessFlash('Users export processing');
    }

    const FetchUserAccounts = async () => {
        setLoadingUsers(true);
        
        let url = `users?page=${currentPage}`;
        url += `&account_status=${userActivityState}`;
        url += `&has_virtual_account=${filterVirtualAccount}`;
        url += `&verified_email=${filterVerifyEmail}`;
        url += `&verified_phone_number=${filterVerifyPhoneNumber}`;
        url += `&account_active=${filterAccountActive}`;
        url += `&account_activated=${filterAccountActivated}`;
        url += `&gender=${filterGender}`;
        url += `&created_from=${createdFrom}`;
        url += `&created_until=${createdTo}`;

        const getUsers = await (new ApiSupport()).get(url);

        if ( getUsers === 'error' ){
            setLoadingUsers(false);
            return;
        }

        const data = JSON.parse(getUsers);

        setUserData(data);

        let newUsers = data.items.map((user:any)=>{
            let statusColor: string = '';
            switch(user.account_status){
                case 'active':
                    statusColor = '#6DD230';
                    break;
                case 'deactivated':
                    statusColor = '#FF0000';
                    break;
                default:
                    statusColor = '#FF7A00';
            }
            return {
                userId: user.phone_number,
                name: `${user.first_name} ${user.last_name}`,
                cTag: user.username,
                email: user.email,
                virtualAccount: user.virtual_account ? user.virtual_account.account_number : <Minus size={14}/>,
                activeSince: moment(user.created_at).format('MMM D, YYYY'),
                status: user.account_status,
                statusColor: statusColor,
            };
        });

        setUserAccounts(newUsers);
        setLoadingUsers(false);
        window.scrollTo(0, 0);
    }

    useEffect(() => {FetchUserAccounts()}, [
        currentPage, 
        userActivityState, 
        filterVirtualAccount,
        filterVerifyEmail,
        filterVerifyPhoneNumber,
        filterAccountActive,
        filterGender,
        filterAccountActivated,
        createdFrom, 
        createdTo,
    ]);

    const onSelectedUserAccount = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.target.checked 
            ? setSelectedUserAccounts([...selectedUserAccounts, event.target.value]) 
            : setSelectedUserAccounts(selectedUserAccounts.filter(item => item !== event.target.value));
    }

    const handleOnClickOutside = (event: MouseEvent) => {
        if (showFiltersRef.current && ! showFiltersRef.current.contains(event.target as Node))  onShowFilter('outsideEvent');
    }

    const onShowFilter = (triggerBy = 'default') => {
    
        const filters = document.getElementById('filters');
        
        if ( triggerBy === 'outsideEvent' ){
            filters?.classList.add(`-translate-y-5`);
            filters?.classList.add('opacity-0');
            filters?.classList.remove('z-20');
            setShowFilters(false);
            return;
        }
        
        filters?.classList.toggle(`-translate-y-5`);
        
        filters?.classList.toggle('opacity-0');
        
        filters?.classList.toggle('z-20');

        setShowFilters(!showFilters); 
    };

    const resetFilter = () => {
        setFilterAccountActivated('all');
        setFilterVerifyEmail('all');
        setFilterVerifyPhoneNumber('all');
        setFilterGender('all');
        setFilterAccountActivated('all');
        setFilterVirtualAccount('all')
        setCurrentPage(1);
        setCreatedFrom(moment().startOf('M').format('YYYY-MM-DD'))
        setCreatedTo(moment().endOf('M').format('YYYY-MM-DD'))
        //onShowFilter();
        
    }

    useEffect(() => {
        // Add event listener when the component mounts
        document.addEventListener('click', handleOnClickOutside);
        
        // Cleanup event listener when the component unmounts
        return () => {
        document.removeEventListener('mousedown', handleOnClickOutside);
        };
      });

    return (
        <AuthLayout>
            <div>
                <div className="items-center space-y-5 lg:justify-between lg:flex md:flex md:space-y-0">
                    <div className='flex items-center space-x-6'>
                        <h1 className="page-title">User Accounts</h1>
                        <span className='text-tertiary font-[600] text-[14px]'>{numberWithCommas(userData?.total ?? 0)} user accounts</span>
                    </div>
                    <div className="flex space-x-3 font-[400] text-tertiary text-[14px] capitalize mt-5 lg:mt-0">
                        {userActivityStates.map((state, index) => (
                            <div onClick={() => setUserActivityState(state)} key={index} className={`${userActivityState === state && 'bg-[#E9ECF1] font-[700]'} rounded-[5px] py-2 cursor-pointer px-5 hover:bg-[#E9ECF1]`}>{state}</div>
                        ))}
                    </div>
                    <button onClick={() =>  ExportUsers()} disabled={loadingExport} type="button" className={`${loadingExport && 'opacity-[0.5]'} px-8 text-white input bg-[#124BB0] w-fit hover:bg-tertiary shadow-sm flex items-center space-x-2`}>
                        <span>Export data</span>
                        {loadingExport && <SpinnerLoader/>}
                    </button>
                </div>
                <div className="relative">
                    <div className="items-center mt-5 lg:justify-between lg:flex">
                        <SearchInput placeholder="Find user"/>
                        <div ref={showFiltersRef} >
                        <div className='text-[13px] flex items-center space-x-5 mt-5 lg:mt-0'>
                                <div onClick={() => onShowFilter()} className='z-10 flex items-center space-x-3 cursor-pointer'>
                                    <Filter size={13}/>
                                    <p className='text-main-dark font-[700]'>Filter by</p>
                                    <ChevronDown size={13}/>
                                </div>
                                <div className='flex items-center space-x-1 text-[#778CA2]'>
                                    <CalendarDays size={13}/>
                                    <p className='font-[500] uppercase'>date</p>
                                </div>
                                <div onClick={() => onShowFilter()} className='z-10 flex items-center space-x-3 cursor-pointer'>
                                    <p className='text-[#252631] font-[500] uppercase'>
                                        {moment(createdFrom).format('MMMM YYYY')}
                                    </p>
                                    <ChevronDown size={13}/>
                                </div>
                            </div>
                            {/* filter */}
                            <div id='filters' className={`transition-all duration-500 ease-in-out transform w-full lg:w-[25em] absolute -translate-y-5 opacity-0
                                                    border border-[#135EE5] p-5 rounded-[15px] bg-white   lg:right-0 lg:top-10 top-24 h-[30em]`}>
                                    <div className='flex justify-between font-[500]'>
                                        <span>Filters</span>
                                        <span onClick={()=> resetFilter()} className='text-red-600 cursor-pointer'>Reset</span>
                                    </div>
                                    <div className='grid h-[25em] mt-5 overflow-y-auto gap-y-6'>
                                        {/* <div>
                                            <h4 className='text-[15px] mb-2'>Is active</h4>
                                            <div className='border input'>
                                                <select onChange={(e)=>setFilterAccountActive(e.target.value)} className='w-full text-gray-600 focus:outline-none'>
                                                    {(['all', 'yes', 'no']).map((item, index) => (
                                                        <option key={index} value={item}>{item}</option>
                                                    ))}
                                                </select>
                                                <ChevronDown size={13}/>
                                            </div>
                                        </div> */}
                                        <div>
                                            <h4 className='text-[15px] mb-2'>Phone number verified</h4>
                                            <div className='border input'>
                                                <select value={filterVerifyPhoneNumber} onChange={(e)=> [setFilterVerifyPhoneNumber(e.target.value), setCurrentPage(1)]} className='w-full text-gray-600 focus:outline-none'>
                                                    {(['all', 'yes', 'no']).map((item, index) => (
                                                        <option key={index} value={item}>{item}</option>
                                                    ))}
                                                </select>
                                                <ChevronDown size={13}/>
                                            </div>
                                        </div>
                                        <div>
                                            <h4 className='text-[15px] mb-2'>Has virtual account</h4>
                                            <div className='border input'>
                                                <select value={filterVirtualAccount} onChange={(e)=> [setFilterVirtualAccount(e.target.value), setCurrentPage(1)]} className='w-full text-gray-600 focus:outline-none'>
                                                    {(['all', 'yes', 'no']).map((item, index) => (
                                                        <option key={index} value={item}>{item}</option>
                                                    ))}
                                                </select>
                                                <ChevronDown size={13}/>
                                            </div>
                                        </div>
                                        <div>
                                            <h4 className='text-[15px] mb-2'>Email verified</h4>
                                            <div className='border input'>
                                                <select value={filterVerifyEmail} onChange={(e) => [setFilterVerifyEmail(e.target.value), setCurrentPage(1)]} className='w-full text-gray-600 focus:outline-none'>
                                                    {(['all', 'yes', 'no']).map((item, index) => (
                                                        <option key={index} value={item}>{item}</option>
                                                    ))}
                                                </select>
                                                <ChevronDown size={13}/>
                                            </div>
                                        </div>
                                        <div>
                                            <h4 className='text-[15px] mb-2'>Gender</h4>
                                            <div className='border input'>
                                                <select value={filterGender} onChange={(e)=>[setFilterGender(e.target.value), setCurrentPage(1)]} className='w-full text-gray-600 focus:outline-none'>
                                                    {(['all', 'male', 'female']).map((item, index) => (
                                                        <option key={index} value={item}>{item}</option>
                                                    ))}
                                                </select>
                                                <ChevronDown size={13}/>
                                            </div>
                                        </div>
                                        <div>
                                            <h4 className='text-[15px] mb-2'>Created From</h4>
                                            <div className='border input'>
                                                <input value={createdFrom} onChange={(e) => setCreatedFrom(e.target.value)} className='w-full bg-transparent focus:outline-none' type='date' />
                                                {/* <CalendarDays size={13}/> */}
                                            </div>
                                        </div>
                                        <div>
                                            <h4 className='text-[15px] mb-2'>Created Until</h4>
                                            <div className='border input'>
                                                <input value={createdTo} onChange={(e) => setCreatedTo(e.target.value)} className='w-full bg-transparent focus:outline-none' type='date' />
                                                {/* <CalendarDays size={13}/> */}
                                            </div>
                                        </div>
                                    </div>
                            </div>
                            {/* --/ filter */}
                        </div>
                    </div>
                    {/* table */}
                    <div className='relative overflow-x-auto'>
                        <table className='mt-10 text-tertiary font-[600] text-[14px] w-full relative z-10'>
                            <thead>
                                <tr className='border-b border-[#E6EAF0]'>
                                    <td className="px-2 py-3">
                                        <input type='checkbox' />
                                    </td>
                                    <td className="px-4 py-3">User ID</td>
                                    <td className="px-6 py-3">Name</td>
                                    <td className="px-6 py-3">Ctag</td>
                                    <td className="px-6 py-3">Email</td>
                                    <td className="px-6 py-3">Virtual account</td>
                                    <td className="px-6 py-3">Active since</td>
                                    <td className="px-6 py-3">Account Status</td>
                                </tr>
                            </thead>
                            <tbody className="relative">
                                { loadingUsers && <TableSkeleton numberOfColumns={8}/> }
                                { ! loadingUsers && userAccounts.map((user, index) => (
                                    <tr key={index}
                                        className='border-b border-[#E6EAF0] relative'
                                        style={{ backgroundColor: selectedUserAccounts.includes(user.cTag) ? '#E6EAF0' : '' }}
                                    >
                                        <td className="px-2 py-3">
                                            <input onChange={(event) => onSelectedUserAccount(event)} value={user.cTag} type='checkbox' className='relative z-10' />
                                            <Link to={`/user-accounts/${user.userId}`} className='absolute top-0 bottom-0 left-0 w-full h-full hover:bg-[#E6EAF0] opacity-[0.2]'/>
                                        </td>
                                        <td className="px-4 py-3">{user.userId}</td>
                                        <td className="px-6 py-3">{user.name}</td>
                                        <td className="px-6 py-3">{user.cTag}</td>
                                        <td className="px-6 py-3">{user.email}</td>
                                        <td className="px-6 py-3">{user.virtualAccount}</td>
                                        <td className="px-6 py-3">{user.activeSince}</td>
                                        <td className="px-6 py-3">
                                            <div className='flex items-center font-[400] bg-[#F8FAFB] w-[10em] py-2 rounded-full relative pl-10'>
                                                <span style={{ backgroundColor: user.statusColor }} className='absolute w-[9px] h-[9px] rounded-full left-5'></span>
                                                <span className='text-[#252631] capitalize'>{user.status}</span>
                                            </div>
                                        </td>
                                    </tr>
                                )) }
                            </tbody>
                        </table>
                    </div>
                    {/*--/ table */}
                    {/* pagination */}
                    {! loadingUsers && (
                        <div className='mt-5'>
                            <TablePagination
                                totalPages={userData?.totalPages ?? 0}
                                onPageChange={(page) => setCurrentPage(page)}
                                currentPage={userData?.page}
                            />
                        </div>
                    )}
                    {/* --/ pagination */}
                </div>
            </div>
        </AuthLayout>
    );
}

export default UserAccounts