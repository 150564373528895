import { BrowserRouter as Router, useRoutes } from 'react-router-dom';
import Auth from "./routes/auth";
import Guest from "./routes/guest";
import NotFound from './routes/not-found';
import React from 'react';
import ScrollToTop from './lib/scroll-to-top';

const Routes = () => {
    const appRoutes = [...Auth, ...Guest, { path: '*', element: <NotFound /> }];
    return useRoutes(appRoutes);
}

const AppRoutes : React.FC = () => {
    return (
        <Router>
            <ScrollToTop/>
            <Routes />
        </Router>
    );
}

export default AppRoutes;
