import React from 'react';
import { Navigate, RouteObject } from 'react-router-dom';
import Dashboard from '../pages/auth/dashboard';
import Transaction from '../pages/auth/transactions/transactions';
import SingleTransaction from '../pages/auth/transactions/single-transaction';
import UserAccounts from '../pages/auth/user-accounts/user-accounts';
import SingleUserAccount from '../pages/auth/user-accounts/single-user-account';
import { AuthToken } from '../lib/auth/auth-store';
import Settings from '../pages/auth/settings';

type RouteType = {
  path: string;
  element: React.ReactNode;
};

const routes: RouteType[] = [
  { path: '/', element: <Dashboard /> },
  { path: '/transactions', element: <Transaction /> },
  { path: '/transactions/:transactionId', element: <SingleTransaction /> },
  { path: '/user-accounts', element: <UserAccounts /> },
  { path: '/user-accounts/:userId', element: <SingleUserAccount /> },
  { path: '/settings', element: <Settings /> },
];

// Check if authenticated
const middleware = () => {
  const isAuthenticated = Boolean(!!AuthToken());
  return routes.map((route) => ({
    path: route.path,
    element: isAuthenticated ? route.element : <Navigate to="/login" />
  }));
}

const AuthRoutes : RouteObject[] = middleware();

export default AuthRoutes;
