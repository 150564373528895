import React, {useState, useEffect} from "react";
import AuthLayout from "../../../components/layouts/auth";
import { Link, useParams } from "react-router-dom";
import { ChevronLeft, Minus, TriangleAlert } from "lucide-react";
import ToggleSwitch from "../../../components/form/toggle-switch";
import LineLoader from "../../../components/loader/line-loader";
import ApiSupport from "../../../lib/api-support";
import { convert_to_naira, numberWithCommas } from "../../../lib/helper-support";
import DefaultModal from "../../../components/modal/default-modal";
import moment from "moment";
import { ErrorFlash, SuccessFlash } from "../../../components/helper/flash-notification";
import Naira from "../../../components/naira";

const SingleUserAccount : React.FC = () => {
    const {userId} = useParams<{userId:string}>();
    const [pageLoading, setPageLoading] = useState<boolean>(true);
    const [userAccount, setUserAccount] = useState<any>(null);
    const [isActivated, setIsActivated] = useState<boolean>(false);
    const [isBanned, setIsBanned] = useState<boolean>(true);
    const [hasVirtualAccount, setHasVirtualAccount] = useState<boolean>(false);

    const getUser = async () => {
        setPageLoading(true);
        const user = await new ApiSupport().get(`users/${userId}`);

        if ( user === 'error' ){
            setPageLoading(false);
            return;
        }
        
        const userAccount = JSON.parse(user);
        setUserAccount(userAccount);
        setIsActivated(userAccount.is_activated);
        setIsBanned(userAccount.is_banned);
        setHasVirtualAccount(userAccount.virtual_account)
        setPageLoading(false);
    }
    
    useEffect(()=> {
        getUser();
        window.scrollTo(0, 0);
    }, []);

    const onAccountActivate = async () => {
    
        let UserAccount = userAccount;

        UserAccount.is_activated = ! UserAccount.is_activated;

        const request = await new ApiSupport().post(`users/${userAccount.phone_number}/accountActivation`, []);

        if ( request === 'error' ){
            ErrorFlash();
            return;
        }
        
        UserAccount.account_status = UserAccount.is_activated ? 'active' : 'deactivated';

        setUserAccount(UserAccount);

        setIsActivated(!isActivated);

        SuccessFlash(`Account ${!isActivated ? 'activated' : 'deactivated'} successfully`);
    }

    const onAccountBan = async () => {
    
        let UserAccount = userAccount;

        const request = await new ApiSupport().post(`users/${userAccount.phone_number}/accountBan`, []);
        
        if ( request === 'error' ){
            ErrorFlash();
            return;
        }
        UserAccount.is_banned = ! UserAccount.is_banned;
        
        setUserAccount(UserAccount);

        setIsBanned(!isBanned);

        SuccessFlash(`Account ${!isBanned ? 'banned' : 'unbanned'} successfully`);
    }

    const regenerateVA = async () => {
        let UserAccount = userAccount;

        const request = await new ApiSupport().post(`users/${userAccount.phone_number}/regenerateVA`, []);
        
        if ( request === 'error' ){
            ErrorFlash();
            return;
        }

        UserAccount.virtual_account = JSON.parse(request).data.account_number;

        setUserAccount(UserAccount);

        setHasVirtualAccount(true);

        SuccessFlash('Virtual account regenerated successfully');
    }
    
    return(
        <AuthLayout>
            {pageLoading && <LineLoader/>}
            {(!pageLoading && userAccount) && (
                <div>
                    <div>
                <h2 className="text-[#8D8D8D] font-[600] text-[14px]">User Accounts / details</h2>
                <div className="flex items-center pl-0 mt-4 mb-10 space-x-2">
                    <span>
                    <Link to={'/user-accounts'}><ChevronLeft size={20} className="-ml-2"/></Link>
                    </span>
                    <h1 className="font-[600] text-tertiary text-[18px]">
                        Cashir user accounts: {userAccount.username}
                    </h1>
                </div>
                <div className="lg:gap-5 lg:flex">
                    <div className="lg:w-3/5">
                        <div className="grid gap-x-8 md:grid-cols-2 gap-y-4 md:gap-y-0">
                            <div className="grid gap-y-4">
                            <div className="flex space-x-5 h-[6em] items-center">
                                <div>
                                <div className="flex items-center justify-center w-[5em] h-[5em] bg-gray-100 rounded-full">
                                    <img src={userAccount.avatar} alt="avatar"/>
                                </div>
                                </div>
                                <div className="w-full">
                                    <label className="font-[400] text-[13px]">
                                        Ctag
                                        <div className="text-tertiary font-[600] text-[16px] input w-fit border border-[#DCDCDC] bg-[#F3F3F3] pr-[3em] text-left">
                                            {userAccount.username}
                                        </div>
                                    </label>
                                    <p className="font-[400] text-[12px] mt-1">
                                        <span className="text-primary">Active since:</span>
                                        &nbsp;
                                        {moment(userAccount.created_at).format('MMM D, YYYY | h:mm a')}
                                    </p>
                                </div>
                            </div>
                            <div className="h-fit">
                                <label className="font-[400] text-[13px]">
                                    Name
                                    <div className="text-tertiary font-[600] text-[16px] input w-full border border-[#DCDCDC] bg-[#F3F3F3]">
                                        {userAccount.first_name} {userAccount.last_name}
                                    </div>
                                </label>
                            </div>
                            <div className="h-fit">
                                <label className="font-[400] text-[13px]">
                                    Email
                                    <div className="text-tertiary font-[600] text-[16px] input w-full border border-[#DCDCDC] bg-[#F3F3F3]">
                                        {userAccount.email}
                                    </div>
                                </label>
                            </div>
                            <div className="h-fit">
                                <label className="font-[400] text-[13px]">
                                    Phone number
                                    <div className="text-tertiary font-[600] text-[16px] input w-full border border-[#DCDCDC] bg-[#F3F3F3]">
                                        {userAccount.phone_number}
                                    </div>
                                </label>
                            </div>
                            <div className="h-fit">
                                <label className="font-[400] text-[13px]">
                                    <div className="flex justify-between">
                                        <span>Virtual account</span>
                                        <span className="font-[400] text-[12px] mt-1">
                                            <span className="text-primary">{userAccount.virtual_account?.bank_name ?? ''}</span>
                                        </span>
                                    </div>
                                    <div className="text-tertiary font-[600] text-[16px] input w-full border border-[#DCDCDC] bg-[#F3F3F3]">
                                        {userAccount.virtual_account?.account_number ?? <Minus size={13}/>}
                                    </div>
                                </label>
                            </div>
                            <div className="h-fit">
                                <label className="font-[400] text-[13px]">
                                    Gender
                                    <div className="text-tertiary font-[600] text-[16px] input w-full border border-[#DCDCDC] bg-[#F3F3F3]">
                                     {userAccount.gender}
                                    </div>
                                </label>
                            </div>
                            <div className="h-fit">
                                <label className="font-[400] text-[13px]">
                                    Relationship status
                                    <div className="text-tertiary font-[600] text-[16px] input w-full border border-[#DCDCDC] bg-[#F3F3F3]">
                                        <Minus size={13} />
                                    </div>
                                </label>
                            </div>
                            <div className="flex space-x-1 h-fit">
                                <label className="font-[400] text-[13px] w-3/5">
                                    Referral
                                    <div className="text-tertiary font-[600] text-[16px] input w-full border border-[#DCDCDC] bg-[#F3F3F3]">
                                        cref_{userAccount.username}
                                    </div>
                                </label>
                                <label className="font-[400] text-[13px] w-2/5">
                                    Referral counter
                                    <div className="text-tertiary font-[600] text-[16px] input w-full border border-[#DCDCDC] bg-[#F3F3F3]">
                                        {userAccount.referral_count}
                                    </div>
                                </label>
                            </div>
                            </div>
                            
                            <div className="grid gap-y-4">
                            <div className="h-[6em]">
                                <label className="font-[400] text-[13px]">
                                    Address of residence
                                    <div className="text-tertiary font-[600] text-[16px] input w-full border border-[#DCDCDC] bg-[#F3F3F3] py-[2em]">
                                        {userAccount.address_information?.address ?? <Minus size={13} />} 
                                    </div>
                                </label>
                            </div>
                            <div className="h-fit">
                                <label className="font-[400] text-[13px]">
                                    LGA of residence
                                    <div className="text-tertiary font-[600] text-[16px] input w-full border border-[#DCDCDC] bg-[#F3F3F3]">
                                        {userAccount.address_information?.lga_of_residence ?? <Minus size={13} />}
                                    </div>
                                </label>
                            </div>
                            <div className="h-fit">
                                <label className="font-[400] text-[13px]">
                                    State of origin
                                    <div className="text-tertiary font-[600] text-[16px] input w-full border border-[#DCDCDC] bg-[#F3F3F3]">
                                        {userAccount.address_information?.state_of_origin ?? <Minus size={13} />}
                                    </div>
                                </label>
                            </div>
                            <div className="h-fit">
                                <label className="font-[400] text-[13px]">
                                    LGA of origin
                                    <div className="text-tertiary font-[600] text-[16px] input w-full border border-[#DCDCDC] bg-[#F3F3F3]">
                                        {userAccount.address_information?.lga_of_origin ?? <Minus size={13} />}
                                    </div>
                                </label>
                            </div>
                            <div className="h-fit">
                                <label className="font-[400] text-[13px]">
                                    Bank verification number (BVN)
                                    <div className="text-tertiary font-[600] text-[16px] input w-full border border-[#DCDCDC] bg-[#F3F3F3]">
                                        {userAccount.bvn ?? <Minus size={13} />}
                                    </div>
                                </label>
                            </div>
                            <div className="h-fit">
                                <label className="font-[400] text-[13px]">
                                    National identification number (NIN)
                                    <div className="text-tertiary font-[600] text-[16px] input w-full border border-[#DCDCDC] bg-[#F3F3F3]">
                                        {userAccount.nin_number ?? <Minus size={13} />}
                                    </div>
                                </label>
                            </div>
                            <div className="h-fit">
                                <label className="font-[400] text-[13px]">
                                    Date of birth
                                    <div className="text-tertiary font-[600] text-[16px] input w-full border border-[#DCDCDC] bg-[#F3F3F3]">
                                        {userAccount.date_of_birth ?? <Minus size={13} />}
                                    </div>
                                </label>
                            </div>
                            <div className="hidden opacity-0 md:block">
                                <label className="font-[400] text-[13px]">
                                    ___
                                    <div className="text-tertiary font-[600] text-[16px] input w-full border border-[#DCDCDC] bg-[#F3F3F3]">
                                        ____
                                    </div>
                                </label>
                            </div>
                            </div>
                        </div>
                    </div>
                    <div className="mt-10 lg:pl-5 lg:w-1/3 md:w-1/2 lg:mt-0">
                        <div className="font-[400] text-[12px] grid grid-cols-3 gap-4">
                            <div className="w-20">
                                <h5 className="mb-1">Phone number verified</h5>
                                <ToggleSwitch initialState={userAccount.has_verified_phone_number} disableToggle={true}/>
                            </div>
                            <div className="w-14">
                                <h5 className="mb-1">Email verified</h5>
                                <ToggleSwitch initialState={userAccount.has_verified_email} disableToggle={true}/>
                            </div>
                            <div className="w-20">
                                <h5 className="mb-1">Identity verified</h5>
                                <ToggleSwitch initialState={userAccount.bvn} disableToggle={true}/>
                            </div>
                        </div>
                        <div className="mt-8">
                            <div className="h-fit">
                                <label className="font-[400] text-[13px]">
                                    Cash balance
                                    <div className="text-tertiary font-[600] text-[16px] input w-full border border-[#DCDCDC] bg-[#F3F3F3] transition duration-300 ease-in">
                                        <h3 className="flex items-end">
                                            <Naira/> {numberWithCommas(convert_to_naira(userAccount.wallet_balance))}.<span className="text-[11px]">{numberWithCommas(convert_to_naira(userAccount.wallet_balance), true, true)}</span>
                                        </h3>
                                    </div>
                                </label>
                            </div>
                        </div>
                        <div className="mt-3">
                            <div className="flex space-x-2 h-fit">
                                <label className="font-[400] text-[13px]">
                                    Account status: <span className="font-[700] capitalize">{userAccount.account_status}</span>
                                    <DefaultModal
                                        icon={<TriangleAlert size={20}/>}
                                        modalButtonTriggerText={userAccount.is_activated ? 'Deactivate' : 'Activate'}
                                        description={`Are you sure you'd like to ${userAccount.is_activated ? 'deactivate' : 'activate'} this user?`}
                                        onConfirmationAction={onAccountActivate}
                                    />
                                </label>
                                <label className="font-[400] text-[13px]">
                                    BVN ban status: <span className="font-[700] capitalize">{userAccount.is_banned ? 'Active' : 'Inactive'}</span>
                                    <DefaultModal
                                        icon={<TriangleAlert size={20}/>}
                                        modalButtonTriggerText={userAccount.is_banned ? 'Unban' : 'Ban'}
                                        description={`Are you sure you'd like to ${userAccount.is_banned ? 'unban' : 'ban'} this user?`}
                                        onConfirmationAction={onAccountBan}
                                    />
                                </label>
                            </div>
                            <div className="flex mt-5 space-x-2 h-fit">
                                <label className="font-[400] text-[13px]">
                                    Has Virtual Account Number: <span className="font-[700] capitalize">{userAccount.virtual_account ? 'Yes' : 'No'}</span>
                                    { (! userAccount.virtual_account && userAccount.bvn) && (
                                        <DefaultModal
                                        icon={<TriangleAlert size={20}/>}
                                        modalButtonTriggerText='Regenerate VA'
                                        description={`Are you sure you'd like to regenerate virtual account number?`}
                                        onConfirmationAction={regenerateVA}
                                        />
                                    )}
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
                
                </div>
            )}
            {!pageLoading && !userAccount && (
                <div>
                    <h1 className="text-[2em] font-[600] text-primary">User Account Not Found</h1>
                    <Link to={'/user-accounts'} className="flex items-center mt-5 space-x-2 font-[400] text-gray-500 hover:underline">
                        <ChevronLeft size={13}/>
                        <span>back to users</span>
                    </Link>
                </div>
            )}
        </AuthLayout>
    );
}

export default SingleUserAccount;