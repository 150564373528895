import { useEffect, useState } from "react";
import ToggleSwitch from "../../components/form/toggle-switch";
import AuthLayout from "../../components/layouts/auth";
import ApiSupport from "../../lib/api-support";
import { ErrorFlash, SuccessFlash } from "../../components/helper/flash-notification";
import SpinnerLoader from "../../components/loader/spinner-loader";
import LineLoader from "../../components/loader/line-loader";

const Settings = () => {
    
    interface ProviderType {
        airtime: string,
        
    }
    
    const [loadingProviders, setLoadingProviders] = useState<boolean>(false);
    
    const [providers, setProviders] = useState<ProviderType>({} as ProviderType);

    const [addCashProvider, setAddCashProvider] = useState<string>(''); //squad as true

    const [bvnVerificationProvider, setBvnVerificationProvider] = useState<string>(''); //okra as true

    const [airtimeDataProvider, setAirtimeDataProvider] = useState<string>(''); //squad as true

    const [sendCashToBankProvider, setSendCashToBankProvider] = useState<string>('');

    const [virtualAccountProvider, setVirtualAccountProvider] = useState<string>(''); //squad as true

    const [tvProvider, setTvProvider] = useState<string>(''); //squad as true

    const [electricityProvider, setElectricityProvider] = useState<string>(''); //squad as true

    const [providerRequiredError, setProviderRequiredError] = useState<string|null>(null);

    const [loadingSwitch, setLoadingSwitch] = useState<string>('');

    const switchProvider = async (feature:string,switchTo:string) => {
        setLoadingSwitch(feature);
        setProviderRequiredError('');
        let selectedProvider = '';
        switch(feature){
            case 'add_cash':
                selectedProvider = switchTo;
                setAddCashProvider(switchTo);
                break;
            case 'bvn':
                selectedProvider = switchTo;
                setBvnVerificationProvider(switchTo);
                break;
            case 'airtime_data':
                selectedProvider = switchTo;
                setAirtimeDataProvider(switchTo);
                break;
            case 'send_cash':
                selectedProvider = switchTo;
                setSendCashToBankProvider(switchTo);
                break;
            case 'virtual_account':
                selectedProvider = switchTo;
                setVirtualAccountProvider(switchTo);
                break;
            case 'tv':
                selectedProvider = switchTo;
                setTvProvider(switchTo);
                break;
            case 'electricity':
                selectedProvider = switchTo;
                setElectricityProvider(switchTo);
                break;
        }

        if ( ! selectedProvider ) {
            setProviderRequiredError(feature);
            setLoadingSwitch('');
            return;
        }

        const request = await new ApiSupport().post('thirdPartySwitch', {
            feature,
            provider: switchTo,
        });
        if ( request === 'error' ){
            setLoadingSwitch('');
            ErrorFlash('Something went wrong');
            return;
        }

        setLoadingSwitch('');

        SuccessFlash(`${feature} switched to ${selectedProvider}`);
    }

    const getProviders = async () => {
        setLoadingProviders(true);
        const request = await new ApiSupport().get('thirdPartyServiceProviders');
        setLoadingProviders(false);
        
        if ( request === 'error' ){
            ErrorFlash('Unable to get infrastructure config');
            return;
        }

        const data = JSON.parse(request);

        const provider = data.data.providers;

        setProviders(data.data.providers);

        setAirtimeDataProvider(provider.airtime);

        setAddCashProvider(provider.add_cash);

        setBvnVerificationProvider(provider.bvn);

        setSendCashToBankProvider(provider.send_cash);

        setVirtualAccountProvider(provider.virtual_account);

        setTvProvider(provider.tv);

        setElectricityProvider(provider.electricity);

        SuccessFlash('Infrastructure synced');
    }

    useEffect(() => {getProviders()}, []);

    return(
        <AuthLayout>
            <div>
                <h1 className="page-title">Settings</h1>
                <div className="mt-10">
                    <h3 className="text-[#2E384D] font-[700] text-[20px]">Infrastructure switches</h3>
                    {loadingProviders && <LineLoader/>}
                    <div className="grid gap-4 mt-8 lg:grid-cols-3 gap-y-8">
                        <div>
                            <h4 className="label text-[#2E384D] text-[14px]">Add Cash *</h4>
                            <div className="bg-[#EDFFF2] mt-1 w-fit relative">
                                <div className="flex items-center p-3 space-x-3">
                                    <span className="text-primary font-[700] text-[15px]">Source Bank</span>
                                    <ToggleSwitch
                                        iconPosition={addCashProvider === 'squad' ? 'right' : 'left'}
                                        onToggle={(state) => {switchProvider('add_cash', state ? 'squad' : 'source_bank')}}
                                        isBothStateTrue={true}
                                    />
                                    <span className="text-primary font-[700] text-[15px]">Squad</span>
                                </div>
                                {loadingSwitch === 'add_cash' && <SwitcherLoader/>}
                            </div>
                        </div>
                        <div>
                            <h4 className="label text-[#2E384D] text-[14px]">BVN verification</h4>
                            <div className="w-fit mt-1 bg-[#EDFFF2] relative">
                                <div className="flex items-center p-3 space-x-3">
                                    <span className="text-primary font-[700] text-[15px]">Source Bank</span>
                                    <ToggleSwitch
                                        iconPosition={bvnVerificationProvider === 'okra' ? 'right' : 'left'}
                                        onToggle={(state) => {switchProvider('bvn', state ? 'okra' : 'source_bank')}}
                                        isBothStateTrue={true}
                                    />
                                    <span className="text-primary font-[700] text-[15px]">OKRA</span>
                                </div>
                                {loadingSwitch === 'bvn' && <SwitcherLoader/>}
                            </div>
                        </div>
                        <div>
                            <h4 className="label text-[#2E384D] text-[14px]">Airtime & internet data</h4>
                            <div className="bg-[#EDFFF2] w-fit mt-1 relative">
                                <div className="flex items-center p-3 space-x-3">
                                    <span className="text-primary font-[700] text-[15px]">Anchor</span>
                                    <ToggleSwitch
                                        iconPosition={airtimeDataProvider === 'squad' ? 'right' : 'left'}
                                        onToggle={(state) => {switchProvider('airtime_data', state ? 'squad' : 'anchor')}}
                                        isBothStateTrue={true}
                                    />
                                    <span className="text-primary font-[700] text-[15px]">Squad</span>
                                </div>
                                {loadingSwitch === 'airtime_data' && <SwitcherLoader/>}
                            </div>
                        </div>
                        <div>
                            <h4 className="label text-[#2E384D] text-[14px]">Send cash to bank *</h4>
                            <div className="bg-[#EDFFF2] w-fit relative mt-1">
                                <div className="grid p-3 gap-y-4">
                                    <div className="flex items-center justify-between space-x-3">
                                        <span className="text-primary font-[700] text-[15px]">Source Bank</span>
                                        <ToggleSwitch
                                            initialState={sendCashToBankProvider === 'source_bank'}
                                            onToggle={(state) => {switchProvider('send_cash', state ? 'source_bank' : '')}}
                                        />
                                    </div>
                                    <div className="flex items-center justify-between space-x-3">
                                        <span className="text-primary font-[700] text-[15px]">Anchor</span>
                                        <div className="flex justify-end">
                                        <ToggleSwitch
                                            initialState={sendCashToBankProvider === 'anchor'}
                                            onToggle={(state) => {switchProvider('send_cash', state ? 'anchor' : '')}}
                                        />
                                        </div>
                                    </div>
                                    <div className="flex items-center justify-between space-x-3 opacity-[0.5]">
                                        <span className="text-primary font-[700] text-[15px]">Squad</span>
                                        <ToggleSwitch
                                            initialState={sendCashToBankProvider === 'squad'}
                                            onToggle={(state) => {switchProvider('send_cash', state ? 'squad' : '')}}
                                            disableToggle={true}
                                        />
                                    </div>
                                </div>
                                {loadingSwitch === 'send_cash' && <SwitcherLoader/>}
                            </div>
                            {providerRequiredError === 'send_cash' && <span className="text-red-600 text-[12px]">Please select a provider</span>}
                        </div>
                        <div>
                            <h4 className="label text-[#2E384D] text-[14px]">Virtual Account *</h4>
                            <div className="bg-[#EDFFF2] w-fit relative mt-1">
                                <div className="flex items-center p-3 space-x-3">
                                    <span className="text-primary font-[700] text-[15px]">Source Bank</span>
                                    <ToggleSwitch
                                        iconPosition={virtualAccountProvider === 'squad' ? 'right' : 'left'}
                                        onToggle={(state) => {switchProvider('virtual_account', state ? 'squad' : 'source_bank')}}
                                        isBothStateTrue={true}
                                    />
                                    <span className="text-primary font-[700] text-[15px]">Squad</span>
                                </div>
                                {loadingSwitch === 'virtual_account' && <SwitcherLoader/>}
                            </div>
                        </div>
                        <div className="grid gap-y-4">
                            <div>
                                <h4 className="label text-[#2E384D] text-[14px]">TV</h4>
                                <div className="bg-[#EDFFF2] relative w-fit mt-1">
                                    <div className="flex items-center p-3 space-x-3">
                                        <span className="text-primary font-[700] text-[15px]">Anchor</span>
                                        <ToggleSwitch
                                            iconPosition={tvProvider === 'squad' ? 'right' : 'left'}
                                            onToggle={(state) => {switchProvider('tv', state ? 'squad' : 'anchor')}}
                                            isBothStateTrue={true}
                                        />
                                        <span className="text-primary font-[700] text-[15px]">Squad</span>
                                    </div>
                                    {loadingSwitch === 'tv' && <SwitcherLoader/>}
                                </div>
                            </div>
                            <div>
                                <h4 className="label text-[#2E384D] text-[14px]">Electricity</h4>
                                <div className="bg-[#EDFFF2] relative w-fit mt-1">
                                    <div className="flex items-center p-3 space-x-3">
                                        <span className="text-primary font-[700] text-[15px]">Anchor</span>
                                        <ToggleSwitch
                                            iconPosition={electricityProvider === 'squad' ? 'right' : 'left'}
                                            onToggle={(state) => {switchProvider('electricity', state ? 'squad' : 'anchor')}}
                                            isBothStateTrue={true}
                                        />
                                        <span className="text-primary font-[700] text-[15px]">Squad</span>
                                    </div>
                                    {loadingSwitch === 'electricity' && <SwitcherLoader/>}
                                </div>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </AuthLayout>
    );
}

const SwitcherLoader = () => {
    return (
        <div className="absolute top-0 left-0 flex items-center justify-center w-full h-full space-x-3 bg-gray-500/90">
            <span className="text-white animate-pulse">Switching</span> <SpinnerLoader/>
        </div>
    )
}

export default Settings