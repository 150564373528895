import { Check, X } from "lucide-react";
import React, {useState} from "react";

type ToggleSwitchType = {
    initialState?:boolean,
    onToggle?: (state: boolean) => void | null,
    disableToggle?: boolean,
    isBothStateTrue?: boolean ,
    iconPosition?: 'left' | 'right'
}
const ToggleSwitch = ({
    initialState = false, 
    onToggle = () => {}, 
    disableToggle = false,
    isBothStateTrue = false,
    iconPosition = 'right',
}:ToggleSwitchType) => {


    const handleToggle = () => {
        if (disableToggle) return;
        if( ! isBothStateTrue ){
            const newState = !initialState;
            onToggle(newState);
            return;
        }

        onToggle(!(iconPosition === 'right'));
       
    }

    return (
        <div onClick={handleToggle} className={`relative grid ${!disableToggle ? 'cursor-pointer' : 'opacity-[0.5]'} content-center w-11 h-6 p-1 rounded-full ${isBothStateTrue ? `bg-primary ${iconPosition === 'right' && 'justify-items-end'}` : (initialState ? `bg-primary ${iconPosition === 'right' && 'justify-items-end'}` : 'bg-gray-300')}`}>
            <div className="grid p-1 bg-white rounded-full w-fit justify-items-center">
                 {isBothStateTrue ?<Check size={10} /> : (initialState ? <Check size={10} />  : <X size={10}/>)}
            </div>
        </div>
    );
}

export default ToggleSwitch