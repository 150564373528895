import CryptoJS from 'crypto-js';

const app_key : string | undefined = process.env.REACT_APP_KEY;

const numberWithCommas = (input: string | number, withDecimal = false, onlyDecimal = false) => {  
    if (withDecimal){
        let hasDecimal = input.toString().split('').indexOf('.') !== -1;

        if (! hasDecimal )  input += '.00';
    }
    
    let parts = input.toString().split('.');

    if ( withDecimal && onlyDecimal ){
        return parts[1].toString();
    }
    
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    if (! withDecimal) return parts[0];
    
    return parts.join('.');
}

const formatNumber = (number:number|string) => {
    number = Number(number);
    if (number >= 1000000) {
        return Math.round(number / 1000000).toFixed(1).replace(/\.0$/, '') + 'm';
    } else if (number >= 1000) {
        return Math.round(number / 1000).toFixed(1).replace(/\.0$/, '') + 'k';
    } else {
        return number.toString();
    }
}

const getTransactionTypeName = (transaction:any) => {
    let transactionType;
    switch(transaction.type){
        case 'user_transfer':
            transactionType = 'Send cash (wallet)';    
        break;
        case 'bank_transfer':
            transactionType = 'Send cash (bank)';    
        break;
        case 'withdraw_cash':
            transactionType = 'Withdraw cash';    
        break;
        case 'fund_wallet':
            transactionType = 'Add cash';    
        break;
        case 'withdraw_cash_commission':
        case 'add_cash_commission':
        case 'bank_transfer_commission':
            transactionType = 'Fee';
            break;
        case 'bill_payment':
            const remark = transaction.remarks?.toLowerCase();
            const narration = transaction.narration?.toLowerCase();
            const descriptor = transaction.descriptor?.toLowerCase();
            if ( remark?.includes('airtime') || narration?.includes('airtime') || descriptor?.includes('airtime') ) return transactionType = 'Airtime';
            if ( remark?.includes('data') || narration?.includes('data') || descriptor?.includes('data') ) return transactionType = 'Internet data';
            if ( remark?.includes('television') || narration?.includes('television') || descriptor?.includes('television') ) return transactionType = 'TV';
            if ( remark?.includes('electricity') || narration?.includes('electricity') || descriptor?.includes('electricity') ) return transactionType = 'Electricity';
            transactionType = 'Bills';
        break;
        default:
            transactionType = transaction.type;
    }

    return transactionType;
}

const TransactionTypes = [
    {
        type: 'user_transfer',
        name: 'send cash (wallet)',
    },
    {
        type: 'bank_transfer',
        name: 'send cash (bank)',
    },
    {
        type: 'fund_wallet',
        name: 'add cash',
    },
    {
        type: 'commission',
        name: 'fee',
    },
    {
        type: 'airtime',
        name: 'airtime',
    },
    {
        type: 'internet data',
        name: 'internet data',
    },
]

const getTransactionColorAndName = (transaction:any) => {
    switch(transaction.status){
        case 'success':
        case 'debited':
            return {
                color: '#009C2C',
                name: 'success',
            }
        case 'failed':
            return {
                color: '#FE4D97',
                name: 'failed',
            }
        default:
            return {
                color: '#FFAB2B',
                name: 'pending',
            }
        
    }
}

const encrypt = (data:string) => {
    return CryptoJS.AES.encrypt(data, app_key ?? '').toString();
}

const decrypt = (data:string) => {
    return CryptoJS.AES.decrypt(data, app_key ?? '').toString(CryptoJS.enc.Utf8);
}

const convert_to_naira = (number:string|number) => {
    number = Number(number);
    return number / 100;
}

export {
    numberWithCommas,
    formatNumber,
    TransactionTypes,
    getTransactionTypeName,
    getTransactionColorAndName,
    encrypt,
    decrypt,
    convert_to_naira,
};